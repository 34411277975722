// This code initiates SPOTIM calls for the cluster articles
(function (window) {
    const { YAHOO } = window;
    // YAHOO.context is exposed via the kaizen-context component
    const { context } = YAHOO;
    const { feature = '' } = context;

    const isWaferCaasArticle = feature.indexOf('cluster') !== -1;

    if (!isWaferCaasArticle) {
        return;
    }

    const comments = {};
    let spotImConfig;

    init();

    function init() {
        const spotimConfigElm: any = document.getElementById('spotim-config');
        const spotImElm: any =
            (spotimConfigElm &&
                window.wafer.utils.getConfigFromJSONScriptNode(
                    spotimConfigElm
                )) ||
            {};
        if (spotImElm?.enabled) {
            spotImConfig = spotImElm || {};
            if (window.wafer) {
                window.wafer.ready(function () {
                    window.wafer.on('caas:article:inview', onArticleInView);
                }, window);
            } else {
                document.body.addEventListener('wafer:ready', function () {
                    window.wafer.on('caas:article:inview', onArticleInView);
                });
            }
        }
    }

    function onArticleInView(event) {
        initCommentsOnView(event);
    }

    function initCommentsOnView(event) {
        if (window.YAHOO) {
            const partnerData = event.meta.data;
            const uuid = partnerData.uuid;
            const viewCmtNode = document.getElementById(
                'spot-im-wafer-lightbox'
            );
            // don't add comments if comments is not allowed
            if (!partnerData.commentsAllowed) {
                if (!viewCmtNode) {
                    return;
                }
                viewCmtNode.style.display = 'none';
            }
            // We've already loaded comments for this article
            if (comments[uuid]) {
                return;
            }

            const id = event.elem.getAttribute('data-comments-id')!;
            initComments(id, partnerData);
        }
    }

    function initComments(id, partnerData) {
        let commentsNode = document.getElementById(id);
        if (!commentsNode) {
            return;
        }

        const { url, uuid, title } = partnerData;
        const { config, m_id } = spotImConfig;
        const spotImMobileLiteCtrl = 'SpotImJACMobileLite';
        const { xhrPathPrefix } = config;

        const spotImParams = {
            ...config,
            title: encodeURIComponent(title),
            url,
            uuid,
        };

        const isMainCommentNodeForDweb =
            commentsNode.id === 'mainArticleIdComments';
        const isMainCommentNodeForMweb = commentsNode.id === 'community-bar';

        const isMain = isMainCommentNodeForDweb || isMainCommentNodeForMweb;

        if (isMainCommentNodeForMweb) {
            const mwebLightboxCommentsNodeId = commentsNode.getAttribute(
                'data-lightbox-comments-id'
            )!;
            const newCommentsNode = mwebLightboxCommentsNodeId
                ? document.getElementById(mwebLightboxCommentsNodeId)
                : '';

            if (newCommentsNode) {
                commentsNode = newCommentsNode;
            }

            // replace the share section placeholder with the actual share bar module data
            const shareSectionNode = document.getElementById('custom-social');
            if (shareSectionNode) {
                const shareFetchUrl = `${xhrPathPrefix}?m_id=${m_id}&ctrl=${spotImMobileLiteCtrl}&m_mode=json`;
                const shareFetchBody = spotImParams
                    ? `data-wf-body=${JSON.stringify({
                          config: spotImParams,
                          ctrl: spotImMobileLiteCtrl,
                          m_id,
                          m_mode: 'json',
                      })}`
                    : '';
                shareSectionNode.innerHTML =
                    '<div class="wafer-fetch" ' +
                    'data-wf-trigger="onLoad" data-wf-result-selector=".custom-social"' +
                    `data-wf-url="${shareFetchUrl}" ${shareFetchBody}></div>`;

                // Sync wafer to know about the added wafer-fetch
                shareSectionNode && window.wafer.base.sync(shareSectionNode);
            }
        }

        // firing event to initiate SpotIm (in JAC), which is being listened in spotIm JAC js file
        const params = {
            detail: {
                config: {
                    ...spotImParams,
                    pitcEnabled: false, // PiTC in Modal shouldn't be shown after each article.
                },
                elem: commentsNode,
                isMain,
            },
        };
        dispatchEvent(new CustomEvent('initSpotImComments', params));

        comments[uuid] = { commentsNode, id };
    }
})(window);
